import HeroComponent from "../../../ui/heroComponent";

const CareersHeroSection = () => {
  return (
    <HeroComponent
      subHeading="Careers"
      heading1="Providing you with an exceptional"
      heading2="banking experience."
    />
  );
};

export default CareersHeroSection;
