import { aboutDetails } from "../../../../data";
import { Card, Image, InnerSection, MainSection } from "../../../ui/styles";

const FeaturedImage = () => {
  return (
    <MainSection
      width="100%"
      padding="110px 0 100px"
      data-aos="fade-up"
    >
      <InnerSection>
        <Card flex mddisplay="grid" mdgridcolumn="1fr 1fr" mdgap="30px" smgap="20px" gap="32px" width="100%">
          {aboutDetails.map((detail, index) => (
            <Card key={index} position="relative" height="490px" smheight="230px" flexratio="1">
              <Image
                src={detail.image}
                position="absolute"
                top={detail.top}
                data-aos="fade-up"
                width="100%"
              />
            </Card>
          ))}
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default FeaturedImage;
