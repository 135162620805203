import { loanOption } from "../../../../data";
import OptionCard from "../../../ui/optionsCard";
import {
  Card,
  gray,
  green,
  InnerSection,
  MainSection,
  TitleText,
} from "../../../ui/styles";

const LoanCarousel = () => {
  return (
    <>
      <MainSection width="100%" bg={gray} padding="55px 0 140px">
        <InnerSection>
          <Card>
            <TitleText
              size="48px"
              smsize="32px"
              smlineheight="42px"
              lineheight="57px"
              color={green}
              heavy
              center
            >
              Loan Option
            </TitleText>
            <Card overflow="scroll" width="100%">
              <Card
                margin="80px 0 0"
                display="grid"
                gridcolumn="repeat(3, 1fr)"
                smgridcolumn="1fr"
                justifycontent="space-between"
                // gap="50px"
                smgap="30px"
                width="100%"
              >
                {loanOption.map((loan, index) => (
                  <OptionCard
                    key={index}
                    title={loan.title}
                    detail1={loan.details1}
                    icon={loan.icon}
                    width="382px"
                  />
                ))}
              </Card>
            </Card>
          </Card>
        </InnerSection>
      </MainSection>
    </>
  );
};

export default LoanCarousel;
