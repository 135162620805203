import { footerNavDetails } from "../../../../data";
import { Card, Image, LinkText, Text, TitleText, white } from "../../styles";

const socialContent = [
  {
    name: "twitter",
    icon: "assets/twitter.png",
    handle: "/",
  },
  {
    name: "linkedin",
    icon: "assets/linkedin.png",
    handle: "/",
  },
  {
    name: "instagram",
    icon: "assets/instagram.png",
    handle: "/",
  },
  {
    name: "facebook",
    icon: "assets/facebook.png",
    handle: "/",
  },
];

const MainFooter = () => {
  return (
    <Card
      flex
      justifycontent="space-between"
      mdflexdirection="column"
      mdwidth="100%"
      mdalignitems="center"
      smalignitems="unset"
      mdgap="50px"
      textalign="left"
    >
      <Card
        flex
        flexdirection="column"
        gap="25px"
        width="270px"
        mdwidth="100%"
        mdalignitems="center"
        smalignitems="unset"
      >
        <Image src="assets/logo.png" width="56px" />
        <Card flex gap="17px" alignitems="center">
          {socialContent.map((data, index) => (
            <a href={data.handle} key={index}>
              <Image src={data.icon} />
            </a>
          ))}
        </Card>
      </Card>
      <Card flex flexdirection="column" gap="20px">
        <TitleText size="20px" lineheight="24px" weight="500" color={white}>
          Quick menu
        </TitleText>
        <Card
          display="grid"
          gridcolumn="1fr 1fr"
          gap="20px"
          mdalignitems="center"
        >
          {footerNavDetails.map((nav, index) => (
            <LinkText
              to={nav.handle}
              key={index}
              width="160px"
              smwidth="155px"
              weight="400"
              size="16px"
              color={white}
              // margin="30px 0 0"
              lineheight="16px"
            >
              {nav.nav}
            </LinkText>
          ))}
        </Card>
      </Card>
      <Card
        flex
        flexdirection="column"
        gap="24px"
        width="290px"
        mdwidth="100%"
        mdalignitems="center"
        smalignitems="unset"
      >
        <Text
          display="flex"
          alignitems="center"
          size="16px"
          lineheight="20px"
          weight="400"
          color={white}
        >
          <Image
            height="13.33px"
            width="9.33px"
            margin="0 10px 0 0"
            src="assets/location-white.png"
          />
          E7 Adisa street Isan-Ekiti, Ekiti State, Nigeria.{" "}
        </Text>
        <a
          href="mailto:abulesormfb@.ng@gmail.com"
          target="_top"
          style={{
            textDecoration: "none",
            color: `${white}`,
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Image height="10px" width="16px" src="assets/mail.png" />
          Abulesoromfb.ng@gmail.com
        </a>
        <a
          href="mailto:abulesormicrofinancebank@gmail.com"
          target="_top"
          style={{
            textDecoration: "none",
            color: `${white}`,
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Image height="10px" width="16px" src="assets/mail.png" />
          abulesoromicrofinancebank@gmail.com
        </a>
        <a
          href="tel:09038746833"
          target="_top"
          style={{
            textDecoration: "none",
            color: `${white}`,
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Image height="10px" width="12px" src="assets/call.png" />
          +234 903 874 6833
        </a>
      </Card>
    </Card>
  );
};

export default MainFooter;
