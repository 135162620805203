import Layout from "../components/ui/layout";
import Explore from "../components/websitePageSections/home/explore";
import FAQ from "../components/websitePageSections/home/faq";
import Features from "../components/websitePageSections/home/features";
import HomeHeroSection from "../components/websitePageSections/home/homeHeroSection";
import ManagingFinance from "../components/websitePageSections/home/managingFinance";
import OpeningAccount from "../components/websitePageSections/home/openingAccount";
import UserFriendly from "../components/websitePageSections/home/userFriendly";

const HomePage = () => {
  return (
    <Layout>
      <HomeHeroSection />
      <UserFriendly />
      <OpeningAccount />
      <Features />
      <Explore />
      <ManagingFinance />
      <FAQ />
    </Layout>
  );
};

export default HomePage;
