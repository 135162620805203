import { useState } from "react";
import { faqDetails } from "../../../../data";
import { black, Card, Image, Text, TitleText, white } from "../../../ui/styles";

const RightDiv = () => {
  const [isClicked, setIsClicked] = useState(faqDetails.length + 1);

  const handleClicked = (index: any) => {
    if (isClicked === index) {
      setIsClicked(faqDetails.length + 1);
    } else {
      setIsClicked(index);
    }
  };

  return (
    <>
      <Card flex flexdirection="column" gap="24px">
        {faqDetails.map((faq, index) => (
          <Card
            key={index}
            width="604px"
            mdwidth="100%"
            padding="28px 32px"
            smpadding="14px 16px"
            radius="10px"
            bg={isClicked === index ? black : white}
            border={`1px solid ${black}`}
            pointer
            onClick={() => handleClicked(index)}
            position="relative"
            transition="0.3s"
            // data-aos="fade-up"
          >
            <Card flex justifycontent="space-between" alignitems="center">
              <TitleText
                heavy
                size="24px"
                mdsize=""
                smsize="18px"
                lineheight="28px"
                smlineheight=""
                color={isClicked === index ? white : black}
                // data-aos="fade-up"
              >
                {index + 1}. {faq.question}
              </TitleText>
              <Image
                src={
                  isClicked === index
                    ? "assets/close-white.png"
                    : "assets/plus.png"
                }
                width="13px"
                height="13px"
              />
            </Card>
            {isClicked === index && (
              <Text
                margin="30px 0 0"
                smmargin="15px 0 0"
                size="14px"
                mdsize=""
                smsize=""
                lineheight="20px"
                smlineheight=""
                color={white}
              >
                {faq.answer}
              </Text>
            )}
          </Card>
        ))}
      </Card>
    </>
  );
};

export default RightDiv;
