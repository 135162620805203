import {
  black,
  Card,
  green,
  InnerSection,
  MainSection,
  TitleText,
} from "../../../ui/styles";
import LeftDiv from "./LeftDiv";

const OpeningAccount = () => {
  return (
    <MainSection width="100%" padding="120px 0 0" mdpadding="0 0 100px">
      <InnerSection>
        <Card
          flex
          justifycontent="space-between"
          // data-aos="fade-up"
          mdflexdirection="column"
          mdgap="20px"
        >
          <TitleText
            weight="700"
            size="48px"
            smsize="30px"
            smlineheight="42px"
            mdsize=""
            lineheight="57px"
            color={black}
            width="555px"
            mdwidth="100%"
          >
            Opening Bank Account{" "}
            <span style={{ color: `${green}` }}>Easier</span>{" "}
          </TitleText>
          <LeftDiv />
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default OpeningAccount;
