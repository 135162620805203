import Layout from '../components/ui/layout'
import DigitalBanking from '../components/websitePageSections/aboutUs/digitalBanking'
import LoanCarousel from '../components/websitePageSections/loans/loanCarousel'
import LoansHeroSection from '../components/websitePageSections/loans/loansHeroSection'
import LoansTablle from '../components/websitePageSections/loans/loansTable'

const LoansPage = () => {
  return (
    <Layout>
      <LoansHeroSection />
      <LoanCarousel />
      <LoansTablle />
      <DigitalBanking />
    </Layout>
  )
}

export default LoansPage