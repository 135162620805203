import Layout from '../components/ui/layout'
import AboutAbulesoro from '../components/websitePageSections/aboutUs/aboutAbulesoro'
import AboutHeroSection from '../components/websitePageSections/aboutUs/aboutHeroSection'
import DigitalBanking from '../components/websitePageSections/aboutUs/digitalBanking'
import FeaturedImage from '../components/websitePageSections/aboutUs/featuredImage'
import Mission from '../components/websitePageSections/aboutUs/mission'
import PerkAndBenefit from '../components/websitePageSections/aboutUs/perkAndBenefit'

const AboutUsPage = () => {
  return (
    <Layout>
     <AboutHeroSection />
     <FeaturedImage />
     <AboutAbulesoro />
     <PerkAndBenefit />
     <Mission />
     <DigitalBanking />
    </Layout>
  )
}

export default AboutUsPage