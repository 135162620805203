import { Card, gray, InnerSection, MainSection } from "../../../ui/styles";
import LeftDiv from "./LeftDiv";
import RightDiv from "./RightDiv";

const Mission = () => {
  return (
    <MainSection width="100%" bg={gray} padding="100px 0">
      <InnerSection>
        <Card
          flex
          justifycontent="space-between"
          alignitems="center"
          mdalignitems="unset"
          mdflexdirection="column"
          mdgap="32px"
        >
          <LeftDiv />
          <RightDiv />
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default Mission;
