import {
  black,
  Card,
  green,
  InnerSection,
  MainSection,
  Text,
  TitleText,
} from "../../../ui/styles";
import PerksAndBenefits from "../../loans/applyForLoans/PerksAndBenefits";

const PerkAndBenefit = () => {
  return (
    <MainSection width="100%" padding="0 0 140px">
      <InnerSection>
        <Card
          flex
          width="100%"
          mdflexdirection="column"
          alignitems="center"
          gap="50px"
        >
          <PerksAndBenefits />
          <Card flexratio="1" flex flexdirection="column" gap="32px">
            <TitleText
              heavy
              size="48px"
              mdsize=""
              smsize="32px"
              smlineheight="42px"
              lineheight="57px"
              color={black}
              width="458px"
              smwidth="100%"
            >
              Perks and benefit of using{" "}
              <span style={{ color: `${green}` }}> Abulesoro</span>{" "}
            </TitleText>
            <Text
              size="18px"
              mdsize=""
              smsize="14px"
              lineheight="28px"
              smlineheight="20px"
            >
              Abulesoro Microfinance Bank offers simple way to save and invest,
              as well as financial education and social impact. It provides
              low-income individuals and small businesses with access to
              affordable financial services. Avoid risky loans or high-interest
              payday advances and choose Abulesoro. <br />
              <br />
              Avoid risky loans or high-interest payday advances and choose
              Abulesoro.
            </Text>
          </Card>
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default PerkAndBenefit;
