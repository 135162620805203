import { black, Card, green, Image, Text, TitleText } from "../../../ui/styles";

const LeftDiv = () => {
  return (
    <Card
      flex
      flexdirection="column"
      gap="20px"
      mdgap="10px"
      width="457px"
      mdwidth="100%"
    >
      <TitleText
        weight="700"
        size="48px"
        mdsize=""
        smsize="32px"
        smlineheight="42px"
        lineheight="57px"
        color={black}
      >
        Got any <span style={{ color: `${green}` }}>questions?</span>{" "}
      </TitleText>
      <Text
        weight="500"
        size="20px"
        mdsize="16px"
        smsize=""
        lineheight="33px"
        mdlineheight="25px"
        color={black}
      >
        Get all your questions answered.
      </Text>
      <Image src="assets/FAQ.png" />
    </Card>
  );
};

export default LeftDiv;
