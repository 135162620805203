import {
  Card,
  Image,
  InnerSection,
  LinkText,
  MainSection,
  primaryGreen,
  white,
} from "../../styles";
import { navDetails } from "../../../../data";
import "./Header.scss";
import { useEffect, useState } from "react";
import HeaderCTA from "./HeaderCTA";

const Header = () => {
  const [scrolled, setScrolled] = useState(true);
  const [isMobileMenu, setIsMobileMenu] = useState(false);

  const headerBackground = () => {
    if (window.pageYOffset > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    headerBackground();
    window.addEventListener("scroll", headerBackground);
    return () => {
      window.removeEventListener("scroll", headerBackground);
    };
  }, []);

  return (
    <>
      <MainSection
        width="100%"
        bg={scrolled ? primaryGreen : "transparent"}
        mdbg={primaryGreen}
        mdposition={`${isMobileMenu ? "fixed" : "fixed"}`}
        position="fixed"
        index="100"
      >
        <InnerSection>
          <Card
            flex
            justifycontent="space-between"
            mdjustifycontent="space-between"
            width="100%"
            alignitems="center"
            padding="20px 0"
            mdpadding="20px 0"
          >
            <LinkText to="/">
              <Image
                width="75px"
                mdwidth="50px"
                smwidth="40px"
                src="assets/logo.png"
              />
            </LinkText>
            <Card
              display="none"
              mddisplay="block"
              width="40px"
              onClick={() => setIsMobileMenu(!isMobileMenu)}
            >
              <Image src="assets/menuicon.png" margin="0 15px" />
            </Card>
            <Card flex gap="90px" alignitems="center" mddisplay="none">
              <Card flex gap="52px" color={white} mddisplay="none">
                {navDetails.map((nav, index) => (
                  <Card key={index} pointer index="15">
                    <>
                      <Card>
                        <LinkText
                          size="14px"
                          lineheight="23px"
                          weight="600"
                          color={white}
                          to={nav.handle}
                        >
                          {nav.nav}
                        </LinkText>
                      </Card>
                    </>
                  </Card>
                ))}
              </Card>
              {/* <HeaderCTA /> */}
            </Card>
          </Card>
        </InnerSection>
        {isMobileMenu && (
          <Card
            display="none"
            mddisplay="block"
            position="fixed"
            width="100vw"
            height="100vh"
            bg={primaryGreen}
            index="100"
            top="70px"
          >
            <InnerSection>
              <Card
                flex
                flexdirection="column"
                gap="20px"
                color={white}
                padding="50px 0"
              >
                {navDetails.map((nav, index) => (
                  <LinkText
                    style={{
                      display: "block",
                      fontWeight: "600",
                      fontSize: "18px",
                      color: `${white}`,
                      margin: "3px 0 0",
                      textDecoration: "none",
                    }}
                    to={nav.handle}
                    key={index}
                    onClick={() => setIsMobileMenu(false)}
                  >
                    {nav.nav}
                  </LinkText>
                ))}
                <HeaderCTA />
              </Card>
            </InnerSection>
          </Card>
        )}
      </MainSection>
    </>
  );
};

export default Header;
