import {
  black,
  Card,
  green,
  InnerSection,
  MainSection,
  Text,
  TitleText,
} from "../../../ui/styles";

const UserFriendly = () => {
  return (
    <MainSection width="100%" padding="300px 0 0" mdpadding="150px 0">
      <InnerSection>
        <Card flex flexdirection="column" gap="34px" alignitems="center" width="1000px" margin="0 auto" mdwidth="100%">
          <TitleText
            weight="700"
            size="48px"
            mdsize=""
            smsize="32px"
            smlineheight="42px"
            smtextalign="left"
            lineheight="57px"
            color={black}
            data-aos="fade-up"
            textalign="center"
            mdtextalign="left"
          >
            Our platform is{" "}
            <span style={{ color: `${green}` }}>
              user-friendly, secure and designed
            </span>{" "}
            to meet your banking needs.
          </TitleText>
          <Text
            weight="500"
            size="20px"
            mdsize="16px"
            center
            mdtextalign="left"
            lineheight="33px"
            smlineheight=""
            color={black}
            width="780px"
            mdwidth="100%"
            data-aos="fade-up"
          >
            Our digital banking service is designed to make your life easier by
            providing 24/7 access to your account from anywhere, anytime.
          </Text>
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default UserFriendly;
