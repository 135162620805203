import { useNavigate } from "react-router-dom";
import { black, Card, green, Image, Text } from "../../../ui/styles";

const LeftDiv = () => {
  const navigate = useNavigate();
  const handleClicked = () => {
    navigate("/savings");
  };
  return (
    <Card flex flexdirection="column" gap="30px">
      <Text
        weight="400"
        size="14px"
        smsize="16px"
        mdsize=""
        lineheight="20px"
        mdlineheight="33px"
        color={black}
        width="500px"
        mdwidth="100%"
      >
        Looking for a banking account with no sign up fee? Look no further than
        our account selection!
        <br />
        <br />
        We offer a wide variety of savings accounts to fit your needs, with no
        hidden fees or charges. Plus, opening an account online is easy and can
        be done in minutes. Choose the account that's right for you and get
        started today!
      </Text>
      <Card flex alignitems="center" gap="15px" pointer onClick={handleClicked}>
        <Image src="assets/arrow.png" />
        <Text
          heavy
          size="20px"
          mdsize=""
          smsize=""
          lineheight="16px"
          smlineheight=""
          color={green}
        >
          Discover more
        </Text>
      </Card>
    </Card>
  );
};

export default LeftDiv;
