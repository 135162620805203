import { black, Card, Text } from "../../../ui/styles";

const RightDiv = () => {
  return (
    <Card flex flexdirection="column" gap="30px">
      <Text
        weight="400"
        size="18px"
        mdsize=""
        smsize="14px"
        lineheight="28px"
        smlineheight="20px"
        color={black}
        width="692px"
        smwidth="100%"
      >
        Abulesoro Microfinance Bank Limited (AMFB) is a newly established bank
        that aims to provide financial services to the grassroots and low income
        earners.
        <br />
        <br />
        Abulesoro Microfinance Bank Limited was incorporated in Nigeria on
        December 19, 2018 under the provisions of the Companies and Allied
        Matters Act CAP C20 LFN 2004 as amended with the registration number
        1548871 and commenced operations on July 1, 2019. It was licensed to
        carry on the business of microfinance banking by the Central Bank of
        Nigeria. <br />
        <br />
        The principal activities of Abulesoro MFB are the provision of banking
        and other financial services to individual customers, institutions in
        the locality, cooperative societies, and small business owners.
      </Text>
    </Card>
  );
};

export default RightDiv;
