import { Card, InnerSection, MainSection } from "../../../ui/styles";
import LeftDiv from "./LeftDiv";
import RightDiv from "./RightDiv";

const FAQ = () => {
  return (
    <MainSection
      width="100%"
      padding="90px 0 150px"
      mdpadding="0  0 100px"
      // data-aos="fade-up"
    >
      <InnerSection>
        <Card
          flex
          justifycontent="space-between"
          mdflexdirection="column"
          mdgap="20px"
          alignitems="center"
        >
          <LeftDiv />
          <RightDiv />
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default FAQ;
