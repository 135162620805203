import { useContext } from "react";
import { exploreDetails } from "../../../../data";
import { exploreContext } from "../../../../helper/Context";
import { Card, Image, TitleText, white } from "../../../ui/styles";

const LeftDiv = () => {
  const { clicked, setClicked } = useContext(exploreContext);
  return (
    <Card flexratio="7" flex flexdirection="column" mdwidth="100%">
      {exploreDetails.map((detail, index) => (
        <Card
          flex
          alignitems="center"
          justifycontent="space-between"
          padding="30px 15px"
          mdpadding="15px"
          key={index}
          pointer
          bbottom={`1px solid ${white}`}
          onClick={() => setClicked(index)}
        >
          <TitleText
            heavy
            size="32px"
            mdsize=""
            smsize="20px"
            lineheight="38px"
            smlineheight=""
            color={clicked === index ? white : "#ffffff90"}
          >
            {detail.nav}
          </TitleText>
          <Image src="assets/arrow-right.png" mdwidth="20px" mdheight="20px" />
        </Card>
      ))}
    </Card>
  );
};

export default LeftDiv;
