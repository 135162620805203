import { useState } from "react";
import {
  Button,
  Card,
  green,
  InnerSection,
  MainSection,
  white,
} from "../../../ui/styles";
import LoanForm from "./LoanForm";
import Table from "./Table";

const LoansTablle = () => {
  const [isLoanForm, setIsLoanForm] = useState(false);
  return (
    <MainSection width="100%" padding="100px 0">
      <InnerSection>
        <Card flex flexdirection="column" gap="50px" alignitems="center">
          <Card
            bg={white}
            padding="50px 40px 36px"
            btop={`4px solid ${green}`}
            shadow="0px 4px 50px rgba(0, 0, 0, 0.05)"
            smwidth="100%"
            overflow="scroll"
          >
            <Table />
          </Card>
          {/* {isLoanForm && <LoanForm />}
          <Button
            padding="16px 20px"
            color={white}
            bg={green}
            radius="10px"
            mdwidth="100%"
            onClick={() => setIsLoanForm(!isLoanForm)}
          >
            {isLoanForm ? "Cancel" : "Apply for loan"}
          </Button> */}
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default LoansTablle;
