import { heroImageDetails } from "../../../../../data";
import { Card, Image, white } from "../../../../ui/styles";

const HeroImage = () => {
  return (
    <Card
      bg={white}
      shadow="0px 4px 50px rgba(0, 0, 0, 0.05)"
      radius="20px"
      padding="20px"
      mdpadding="10px"
      smpadding="0"
      flex
      gap="20px"
      mdgap="5px"
      mdjustifycontent="space-between"
      margin="38px 0 0"
      position="relative"
      border="13px solid rgba(36, 142, 56, 0.2)"
      // borderimage="linear-gradient(180deg, rgba(36, 142, 56, 0.2) 33.33%, rgba(255, 255, 255, 0) 68.75%)"
      data-aos="fade-up"
      mdwidth="100%"
      justifycontent="space-between"
      index="2"
    >
      <Image
        src="assets/heroaesthetic.png"
        position="absolute"
        top="-80px"
        right="-80px"
        mddisplay="none"
      />
      {heroImageDetails.map((image, index) => (
        <Card
          radius="10px"
          height="432px"
          mdheight="400px"
          // flexratio="1"
          width="164px"
          mdwidth="150px"
          smdisplay="none"
          key={index}
        >
          <Image src={image} data-aos="fade-up" height="100%" width="100%" />
        </Card>
      ))}
      <Card
        width="100%"
        display="none"
        smdisplay="block"
      >
        <Image src="assets/about1.png" data-aos="fade-up" width="100%" />
      </Card>
    </Card>
  );
};

export default HeroImage;
