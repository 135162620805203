import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { exploreDetails } from "../../../../data";
import { exploreContext } from "../../../../helper/Context";
import {
  Card,
  green,
  Image,
  primaryGreen,
  Text,
  TitleText,
  white,
} from "../../../ui/styles";

const RightDiv = () => {
  const { clicked } = useContext(exploreContext);
  const navigate = useNavigate();
  const handleClicked = (handle: any) => {
    navigate(handle);
  };
  return (
    <Card flexratio="5" padding="45px 50px" smpadding="20px" radius="20px" bg={primaryGreen}>
      {exploreDetails.map(
        (detail, index) =>
          clicked === index && (
            <Card key={index} flex flexdirection="column" gap="30px">
              <TitleText
                heavy
                size="24px"
                mdsize=""
                smsize="20px"
                lineheight="28px"
                smlineheight=""
                color={white}
              >
                {detail.heading}
              </TitleText>
              <Text
                size="14px"
                mdsize=""
                smsize=""
                lineheight="20px"
                smlineheight=""
                color={white}
              >
                {detail.details1} <br />
                <br /> {detail.details2} <br />
                <br /> {detail.details3}
              </Text>
              <Card
                flex
                alignitems="center"
                gap="15px"
                pointer
                onClick={() => handleClicked(detail.handle)}
              >
                <Image src="assets/arrow.png" />
                <Text
                  heavy
                  size="20px"
                  mdsize=""
                  smsize=""
                  lineheight="16px"
                  smlineheight=""
                  color={green}
                >
                  Discover more
                </Text>
              </Card>
            </Card>
          )
      )}
    </Card>
  );
};

export default RightDiv;
