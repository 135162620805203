import Layout from '../components/ui/layout'
import ContactForm from '../components/websitePageSections/contactUs/contactForm'
import ContactHeroSection from '../components/websitePageSections/contactUs/contactHeroSection'

const ContactUsPage = () => {
  return (
    <Layout>
      <ContactHeroSection />
      <ContactForm />
    </Layout>
  )
}

export default ContactUsPage