import HeroComponent from "../../../ui/heroComponent";

const AboutHeroSection = () => {
  return (
    <HeroComponent
      subHeading="About us"
      heading1="Providing you with an exceptional"
      heading2="banking experience."
    />
  );
};

export default AboutHeroSection;
