import { useState } from "react";
import { exploreContext } from "../../../../helper/Context";
import {
  Card,
  deepGreen,
  green,
  InnerSection,
  MainSection,
  TitleText,
  white,
} from "../../../ui/styles";
import LeftDiv from "./LeftDiv";
import RightDiv from "./RightDiv";

const Explore = () => {
  const [clicked, setClicked] = useState(0);
  return (
    <exploreContext.Provider value={{ clicked, setClicked }}>
      <MainSection width="100%" bg={deepGreen} padding="70px 0 60px">
        <InnerSection>
          <Card flex flexdirection="column" gap="64px" alignitems="center">
            <TitleText
              size="48px"
              mdsize=""
              lineheight="57px"
              smsize="32px"
              smlineheight="42px"
              color={white}
              width="510px"
              mdwidth="100%"
              center
              heavy
            >
              Explore{" "}
              <span style={{ color: `${green}` }}>How You Can Grow</span> With
              Us
            </TitleText>
            <Card
              flex
              alignitems="center"
              gap="32px"
              width="100%"
              mdflexdirection="column"
            >
              <LeftDiv />
              <RightDiv />
            </Card>
          </Card>
        </InnerSection>
      </MainSection>
    </exploreContext.Provider>
  );
};

export default Explore;
