import { black, Card, green, Text, TitleText } from "../../../ui/styles";

const LeftDiv = () => {
  return (
    <Card flex flexdirection="column" gap="30px" smgap="10px">
      <TitleText
        heavy
        size="96px"
        lineheight="115px"
        mdsize=""
        mdlineheight=""
        smsize="70px"
        smlineheight="80px"
        color={green}
        data-aos="fade-up"
      >
        10 +
      </TitleText>
      <Text
        heavy
        size="20px"
        lineheight="24px"
        mdsize="16px"
        mdlineheight="20px"
        smsize=""
        smlineheight=""
        color={green}
        data-aos="fade-up"
      >
        Years Experience
      </Text>
      <Text
        heavy
        margin="20px 0 0"
        smmargin="0"
        size="20px"
        lineheight="28px"
        mdsize="16px"
        mdlineheight="20px"
        smsize=""
        smlineheight=""
        color={black}
        width="424px"
        smwidth="100%"
        data-aos="fade-up"
      >
        We have been triumphing all these 10 years Sacrifice are made up with
        success
      </Text>
    </Card>
  );
};

export default LeftDiv;
