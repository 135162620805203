import Layout from '../components/ui/layout'
import DigitalBanking from '../components/websitePageSections/aboutUs/digitalBanking'
import SavingsAndAccountOption from '../components/websitePageSections/savingsAndAccounts/savingsAndAccountOption'
import SavingsHeroSection from '../components/websitePageSections/savingsAndAccounts/savingsHeroSection'

const SavingsAndAccountsPage = () => {
  return (
    <Layout>
      <SavingsHeroSection />
      <SavingsAndAccountOption />
      <DigitalBanking />
    </Layout>
  )
}

export default SavingsAndAccountsPage