export const navDetails = [
  {
    nav: "Home",
    handle: "/",
  },
  {
    nav: "About",
    handle: "/about",
  },
  {
    nav: "Account",
    handle: "/savings",
  },
  {
    nav: "Loans",
    handle: "/loans",
  },
  {
    nav: "Careers",
    handle: "/careers",
  },
  {
    nav: "Contact",
    handle: "/contact",
  },
];

export const footerNavDetails = [
  {
    nav: "Loans",
    handle: "/loans",
  },
  {
    nav: "Account",
    handle: "/savings",
  },
  {
    nav: "E-Banking",
    handle: "/",
  },
  // {
  //   nav: "Open Account",
  //   handle: "/",
  // },
];

export const heroImageDetails = [
  "assets/hero1.png",
  "assets/hero2.png",
  "assets/hero3.png",
  "assets/hero4.png",
];

export const aboutDetails = [
  {
    image: "assets/about1.png",
    top: "0",
  },
  {
    image: "assets/about2.png",
    top: "77px",
  },
  {
    image: "assets/about3.png",
    top: "0",
  },
  {
    image: "assets/about4.png",
    top: "77px",
  },
];

export const exploreDetails = [
  {
    nav: "Loans",
    heading: "Apply For Loan",
    details1:
      "Do you need a loan but don't want to deal with the hassle of paperwork? Look no further than our online banking service!",
    details2: "We offer low interest rates with no paperwork required.",
    details3: "Apply, and you'll be approved for a loan in no time!",
    handle: "/loans",
  },
  {
    nav: "E-Banking",
    heading: "Our E-Banking",
    details1:
      "With e-banking, you can view account balances and transactions, pay bills, andtransfer funds between accounts.",
    details2:
      "E-banking provides a seamless transaction experience. You can conduct all of your banking activities in one place without having to switch between different websites or applications.",
    details3:
      "E-banking also allows you to take advantage of 24/7 customer support. If you're looking for a convenient and user-friendly way to bank, consider using e-banking.",
    handle: "/",
  },
];

export const faqDetails = [
  {
    question: "Apply For Loan",
    answer:
      'Log on to Abulesoromfb.ng/loans and click on "Open an Account”. Fill in the necessary information and get your account verified, and you are good to go.',
  },
  {
    question: "How can I open an account",
    answer:
      "Log on to Abulesoromfb.ng/loans then click on open an account. Fill in the necessary information and get your account verified, and you are good to go.",
  },
  {
    question: "How can I apply for a loan?",
    answer:
      "Log on to Abulesoromfb.ng/loans click on loans, fill in the necessary information, and await approval of the loan. ",
  },
  {
    question: "Are there extra or hidden charges?",
    answer:
      "There are no hidden charges for transactions made on your account.",
  },
];

export const missionDetails = [
  {
    heading: "Our Mission",
    detail:
      "To offer full range of Microfinance services to our customers in a timely and efficient manner with international best practices based on financial and operational sustainable model.",
  },
  {
    heading: "Our Vision",
    detail:
      "To be a foremost and reputable microfinance services provider, recognized for its swift response to in customers’ needs measured in terms of speed, efficiency, flexibility, access, cost and create value for our stakeholders.",
  },
  {
    heading: "Core Values",
    details: [
      "Availability of digital banking platform.",
      "Availability of ATM cards for ease withdrawal and transfer",
      "Access to salary advance and overdraft to salary earners.",
      "Target individual loans.",
      "Target and voluntary savings plans.",
      "Agency banking platform and POS services.",
    ],
  },
];

export const perkDetails = [
  "Achievement of target project.",
  "Encourage savings.",
  "Prompt flexible and effective on-line banking",
  "Credit facilities opportunity.",
  "Project financing opportunity.",
];

export const loanOption = [
  {
    icon: "assets/loan.png",
    title: "SME Loans",
    details1:
      "We promote long-term growth by investing in small and medium-sized businesses. We offer loans with low interest rates and flexible payback terms. ",
    handle: "#",
  },
  {
    icon: "assets/loan.png",
    title: "Housing Loans",
    details1:
      "We provide you with the opportunity to achieve your dream of becoming a homeowner. We offer loans with low interest rates and flexible payback terms.",
    handle: "#",
  },
  {
    icon: "assets/loan.png",
    title: "Micro Loans",
    details1:
      "We understand that everyone's financial needs are different, which is why we offer a wide range of products and services to meet your individual needs. From checking and savings accounts to loans and credit cards, we have everything you need to manage your finances with confidence",
    handle: "#",
  },
];

export const savingsAccountOption = [
  {
    icon: "assets/savings.png",
    title: "Premium savings",
    details:
      "We understand that everyone's financial needs are different, which is why we offer a wide range of products and services to meet your individual needs. From checking and savings accounts to loans and credit cards, we have everything you need to manage your finances with confidence",
    handle: "#",
    details2:"",
    details3:""
  },
  {
    icon: "assets/fixed.png",
    title: "Future leader savings",
    details:
      "We understand that everyone's financial needs are different, which is why we offer a wide range of products and services to meet your individual needs. From checking and savings accounts to loans and credit cards, we have everything you need to manage your finances with confidence",
    handle: "#",
    details2:"",
    details3:""
  },
  {
    icon: "assets/on-time.png",
    title: "Staff salary account",
    details:
      "We understand that everyone's financial needs are different, which is why we offer a wide range of products and services to meet your individual needs. From checking and savings accounts to loans and credit cards, we have everything you need to manage your finances with confidence",
    handle: "#",
    details2:"",
    details3:""
  },
  {
    icon: "assets/money-box.png",
    title: "Daily Contributions",
    details:
      "We understand that everyone's financial needs are different, which is why we offer a wide range of products and services to meet your individual needs. From checking and savings accounts to loans and credit cards, we have everything you need to manage your finances with confidence",
    handle: "#",
    details2:"",
    details3:""
  },
];

export const currentAccountOption = [
  {
    icon: "assets/savings.png",
    title: "Individual or personal",
    details:
      "We understand that everyone's financial needs are different, which is why we offer a wide range of products and services to meet your individual needs. From checking and savings accounts to loans and credit cards, we have everything you need to manage your finances with confidence",
    handle: "#",
    details2:"",
    details3:""
  },
  {
    icon: "assets/fixed.png",
    title: "Enterprise",
    details:
      "We understand that everyone's financial needs are different, which is why we offer a wide range of products and services to meet your individual needs. From checking and savings accounts to loans and credit cards, we have everything you need to manage your finances with confidence",
    handle: "#",
    details2:"",
    details3:""
  },
  {
    icon: "assets/fixed.png",
    title: "Corporate",
    details:
      "We understand that everyone's financial needs are different, which is why we offer a wide range of products and services to meet your individual needs. From checking and savings accounts to loans and credit cards, we have everything you need to manage your finances with confidence",
    handle: "#",
    details2:"",
    details3:""
  },
  {
    icon: "assets/fixed.png",
    title: "Fixed Deposit",
    details:
      "We understand that everyone's financial needs are different, which is why we offer a wide range of products and services to meet your individual needs. From checking and savings accounts to loans and credit cards, we have everything you need to manage your finances with confidence",
    handle: "#",
    details2:"",
    details3:""
  },
];

export const careerDetails = [
  {
    role: "Product Designer",
    description:
      "We are looking for a mid - level product desiger to join our team",
    handle: "",
    tags: [
      {
        icon: "assets/location.png",
        tag: "100% Remote",
      },
      {
        icon: "assets/time.png",
        tag: "Full Time",
      },
    ],
  },
  {
    role: "Product Designer",
    description:
      "We are looking for a mid - level product desiger to join our team",
    handle: "",
    tags: [
      {
        icon: "assets/location.png",
        tag: "100% Remote",
      },
      {
        icon: "assets/time.png",
        tag: "Full Time",
      },
    ],
  },
  {
    role: "Product Designer",
    description:
      "We are looking for a mid - level product desiger to join our team",
    handle: "",
    tags: [
      {
        icon: "assets/location.png",
        tag: "100% Remote",
      },
      {
        icon: "assets/time.png",
        tag: "Full Time",
      },
    ],
  },
  {
    role: "Product Designer",
    description:
      "We are looking for a mid - level product desiger to join our team",
    handle: "",
    tags: [
      {
        icon: "assets/location.png",
        tag: "100% Remote",
      },
      {
        icon: "assets/time.png",
        tag: "Full Time",
      },
    ],
  },
  {
    role: "Product Designer",
    description:
      "We are looking for a mid - level product desiger to join our team",
    handle: "",
    tags: [
      {
        icon: "assets/location.png",
        tag: "100% Remote",
      },
      {
        icon: "assets/time.png",
        tag: "Full Time",
      },
    ],
  },
];

export const upperFeaturesDetails = ["Bank Transfer", "Loans", "Buy Airtime/Data"];
export const lowerFeaturesDetails = [
  "Bill Payment",
  "Free Transfer",
  "Bill Payment",
  "Free ATM Card",
];
