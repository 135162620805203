import { black, Card, InnerSection, MainSection } from "../../styles";
import FooterContact from "./FooterContact";
import MainFooter from "./MainFooter";

const Footer = () => {
  return (
    <MainSection
      padding="90px 0 160px"
      mdpadding="200px 0 100px"
      smpadding="100px 0"
      bg={black}
    >
      <InnerSection>
        <Card flex flexdirection="column" width="100%" gap="60px" smgap="50px">
          <FooterContact />
          <MainFooter />
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default Footer;
