import { black, Card, green, TitleText } from "../../../ui/styles";

const tableHead = [
  { head: "CHARGES AND INTERESTS", id: 10 },
  { head: "TENOR", id: 11 },
];

const loanDetails = [
  {
    interest: "6% FLAT INTEREST ON EACH LOAN",
    tenor: "61%",
  },
  {
    interest: "1% MANAGEMENT FEE",
    tenor: "61%",
  },
  {
    interest: "1% PROCESSING FEE",
    tenor: "61%",
  },
  {
    interest: "1% INSURANCE FEE",
    tenor: "61%",
  },
];

const Table = () => {
  return (
    <Card flex flexdirection="column" gap="37px" width="804px">
      <Card flex justifycontent="space-between">
        {tableHead.map((head, index) => (
          <TitleText
            color={green}
            size="20px"
            // smsize="14px"
            // smlineheight="20px"
            lineheight="24px"
            key={index}
            heavy
          >
            {head.head}
          </TitleText>
        ))}
      </Card>
      <Card flex flexdirection="column" gap="36px">
        {loanDetails.map((loan, index) => (
          <Card key={index} flex justifycontent="space-between">
            <TitleText
              color={black}
              size="20px"
              lineheight="24px"
              //   smsize="14px"
              //   smlineheight="20px"
              heavy
              width="350px"
            >
              {loan.interest}
            </TitleText>
            <TitleText
              color={black}
              size="20px"
              lineheight="24px"
              //   smsize="14px"
              //   smlineheight="20px"
              width="70px"
              textalign="center"
            >
              {loan.tenor}
            </TitleText>
          </Card>
        ))}
      </Card>
      {/* <TitleText
        color={green}
        size="16px"
        lineheight="16px"
        heavy
        width="100%"
        textalign="center"
        pointer
        
      >
        Calculate Loan
      </TitleText> */}
    </Card>
  );
};

export default Table;
