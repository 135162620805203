import {
  Card,
  green,
  Image,
  InnerSection,
  MainSection,
  primaryGreen,
  Text,
  TitleText,
  white,
} from "../styles";

interface IComponentProps {
  subHeading?: string;
  heading1?: string;
  heading2?: string;
}

const HeroComponent = (props: IComponentProps) => {
  const { subHeading, heading1, heading2 } = props;
  return (
    <MainSection
      width="100%"
      bg={primaryGreen}
      padding="180px 0 220px"
      smpadding="150px 0 100px"
      position="relative"
      index="0"
    >
      <Image
        src="assets/hero.png"
        position="absolute"
        right="0"
        bottom="0"
        index="-1"
        height="100%"
        smdisplay="none"
        smwidth="30%"
      />
      <Image
        display="none"
        src="assets/hero-arc.png"
        position="absolute"
        right="0"
        bottom="0"
        index="-1"
        smdisplay="block"
        smwidth="30%"
      />
      <InnerSection>
        <Card width="800px" mdwidth="100%">
          <Text
            size="20px"
            lineheight="20px"
            weight="500"
            color={white}
            data-aos="fade-up"
          >
            {subHeading}
          </Text>
          <TitleText
            size="48px"
            lineheight="57px"
            smsize="32px"
            smlineheight="42px"
            heavy
            color={white}
            margin="20px 0 0"
            data-aos="fade-up"
          >
            {heading1} <span style={{ color: `${green}` }}>{heading2}</span>
          </TitleText>
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default HeroComponent;
