import { useNavigate } from "react-router-dom";
import { Card, white, TitleText, green, Button } from "../../styles";


const FooterContact = () => {
  const navigate = useNavigate()
  const handleContact = () => {
  navigate('/contact')
  }
  return (
    <Card
      flex
      justifycontent="space-between"
      alignitems="center"
      padding="0 0 47px"
      bbottom={`1px solid ${white}`}
      mdflexdirection="column"
      mdgap="20px"
    >
      <TitleText
        width="564px"
        mdwidth="100%"
        weight="700"
        size="48px"
        smsize="32px"
        smlineheight="42px"
        mdtextalign="center"
        color={white}
        lineheight="57px"
        textalign="left"
      >
        Let’s help you{" "}
        <span style={{ color: `${green}` }}>manage your finances</span> with
        ease
      </TitleText>
      <Button
        padding="16px 31px"
        size="16px"
        heavy
        color={white}
        bg={green}
        radius="10px"
        onClick={handleContact}
      >
        Contact Us
      </Button>
    </Card>
  );
};

export default FooterContact;
