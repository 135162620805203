import {
  AboutUsPage,
  CareersPage,
  ContactUsPage,
  HomePage,
  LoansPage,
  SavingsAndAccountsPage,
} from "./pages";

export const routes = [
  {
    title: "Home Page",
    subRoutes: [],
    component: <HomePage />,
    route: "/",
  },
  {
    title: "About Us Page",
    subRoutes: [],
    component: <AboutUsPage />,
    route: "/about",
  },
  {
    title: "Contact Us Page",
    subRoutes: [],
    component: <ContactUsPage />,
    route: "/contact",
  },
  {
    title: "Careers Page",
    subRoutes: [],
    component: <CareersPage />,
    route: "/careers",
  },
  {
    title: "Loans Page",
    subRoutes: [],
    component: <LoansPage />,
    route: "/loans",
  },
  {
    title: "Savings and Accounts Page",
    subRoutes: [],
    component: <SavingsAndAccountsPage />,
    route: "/savings",
  },
];
