import {
  Button,
  Card,
  deepGreen,
  green,
  InnerSection,
  MainSection,
  TitleText,
  white,
} from "../../../ui/styles";

const ManagingFinance = () => {
  return (
    <MainSection width="100%" padding="100px 0">
      <InnerSection>
        <Card
          bg={deepGreen}
          radius="20px"
          padding="70px 95px"
          smpadding="30px 20px"
          flex
          alignitems="center"
          flexdirection="column"
          gap="32px"
          data-aos="fade-up"
        >
          <TitleText
            weight="700"
            size="48px"
            mdsize=""
            smsize="32px"
            smlineheight="42px"
            lineheight="57px"
            color={white}
            width="680px"
            mdwidth="100%"
            center
            data-aos="fade-up"
          >
            Start{"  "}
            <span style={{ color: `${green}` }}>
              Banking and Manage Your
            </span>{" "}
            Finances
          </TitleText>
          {/* <Button
            padding="16px 20px"
            color={green}
            bg={white}
            radius="10px"
            data-aos="fade-up"
          >
            Open an account
          </Button> */}
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default ManagingFinance;
