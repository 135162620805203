import {
  black,
  Card,
  green,
  InnerSection,
  MainSection,
  TitleText,
} from "../../../ui/styles";
import RightDiv from "./RightDiv";

const AboutAbulesoro = () => {
  return (
    <MainSection width="100%" padding="80px 0 110px" mdpadding="100px 0" data-aos="fade-up">
      <InnerSection>
        <Card
          flex
          justifycontent="space-between"
          mdflexdirection="column"
          mdgap="20px"
          alignitems="center"
        >
          <TitleText
            heavy
            size="48px"
            mdsize=""
            smsize="32px"
            smlineheight="42px"
            lineheight="57px"
            color={black}
            width="428px"
            smwidth="100%"
          >
            Learn more about{" "}
            <span style={{ color: `${green}` }}>Abulesoro</span>{" "}
          </TitleText>
          <RightDiv />
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default AboutAbulesoro;
