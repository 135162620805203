import {
  Button,
  Card,
  green,
  LinkText,
  primaryGreen,
  white,
} from "../../styles";

const HeaderCTA = () => {
  return (
    <Card flex gap="24px" mdflexdirection="column">
      <LinkText to="/" className="link">
        <Button
          padding="16px 20px"
          color={white}
          bg={green}
          radius="10px"
          mdwidth="100%"
        >
          Login
        </Button>
      </LinkText>
      <LinkText to="/" className="link">
        <Button
          padding="16px 20px"
          color={primaryGreen}
          bg={white}
          radius="10px"
          mdwidth="100%"
        >
          Open an account
        </Button>
      </LinkText>
    </Card>
  );
};

export default HeaderCTA;
