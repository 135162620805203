import {
  Card,
  Image,
  InnerSection,
  MainSection,
  Text,
  TitleText,
  white,
} from "../styles";

interface IPerksDetails {
  icon?: string;
  title?: string;
  detail?: string;
  handle?: string;
  index?: any;
  setPopup?: any;
}

const Perks = (props: IPerksDetails) => {
  const { icon, title, detail, handle, index, setPopup } = props;
  return (
    <MainSection width="100%" mdpadding="100px 0" data-aos="fade-up">
      <InnerSection>
        <Card
          flex
          mdflexdirection="column"
          alignitems="center"
          gap="100px"
          mdgap="30px"
          bg={white}
          radius="20px"
          smradius="10px"
          padding="40px 50px"
          smpadding="40px 20px 50px"
          position="relative"
        >
          <Image
            src="assets/close.png"
            position="absolute"
            top="18px"
            right="18px"
            pointer
            onClick={() => setPopup(false)}
          />
          <Card flexratio="5">
            <Image
              src="assets/savingsperk.png"
              width="100%"
              mdwidth="100%"
              radius="10px"
            />
          </Card>
          <Card flexratio="4" flex flexdirection="column" gap="13px">
            <TitleText
              heavy
              size="20px"
              mdsize=""
              smsize=""
              lineheight="33px"
              smlineheight=""
            >
              {title}
            </TitleText>
            <Text
              size="14px"
              mdsize=""
              smsize=""
              lineheight="20px"
              smlineheight=""
            >
              {detail}
            </Text>
          </Card>
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default Perks;
