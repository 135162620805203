import {
  Button,
  Card,
  green,
  InnerSection,
  MainSection,
  primaryGreen,
  Text,
  TitleText,
  white,
} from "../../../ui/styles";
import AnimatingText from "./AnimatingText";
import BackgroundGradient from "./BackgroundGradient";
import Ellipse from "./Ellipse";
import HeroImage from "./heroImage/HeroImage";

const HomeHeroSection = () => {
  return (
    <>
      <MainSection
        width="100%"
        padding="150px 0 100px"
        mdpadding="100px 0"
        bg={primaryGreen}
        height="100vh"
        smheight="unset"
        maxheight="810px"
        position="relative"
      >
        <Card
          width="100%"
          height="100%"
          overflow="hidden"
          position="absolute"
          top="0"
          left="0"
          index="1"
        >
          <BackgroundGradient />
        </Card>
        <InnerSection>
          <Card
            flex
            flexdirection="column"
            gap="30px"
            alignitems="center"
            smalignitems="unset"
            position="relative"
            index="1"
          >
            <TitleText
              size="64px"
              smsize="32px"
              smtextalign="left"
              lineheight="76px"
              smlineheight="42px"
              weight="700"
              width="640px"
              smwidth="100%"
              center
              color={white}
              data-aos="fade-up"
            >
              Experience Banking{" "}
              <span style={{ color: `${green}` }}>Like Never Before</span>
            </TitleText>
            <Text
              size="20px"
              smsize="16px"
              lineheight="20px"
              weight="500"
              color={white}
            >
              Introducing Our Innovative Digital Banking Services.
            </Text>
            {/* <Button
              padding="16px 20px"
              color={green}
              bg={white}
              radius="10px"
              mtop="14px"
            >
              Open an account
            </Button> */}
            <Ellipse />
            <AnimatingText />
            <HeroImage />
          </Card>
        </InnerSection>
      </MainSection>
    </>
  );
};

export default HomeHeroSection;
