import { Card, MainSection } from "../../../ui/styles";
import LowerFeatures from "./LowerFeatures";
import UpperFeatures from "./UpperFeatures";

const Features = () => {
  return (
    <MainSection
      width="100%"
      padding="90px 0 150px"
      mdpadding="0 0 100px"
      maxwidth="1440px"
    >
      <Card flex flexdirection="column" gap="60px" mdgap="30px">
        <UpperFeatures />
        <LowerFeatures />
      </Card>
    </MainSection>
  );
};

export default Features;
