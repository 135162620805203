import {
  Button,
  Card,
  deepGreen,
  green,
  Image,
  InnerSection,
  MainSection,
  TitleText,
  white,
} from "../../../ui/styles";

const DigitalBanking = () => {
  return (
    <MainSection
      width="100%"
      padding="128px 0"
      mdpadding="100px 0"
      bg={deepGreen}
      // mbottom="150px"
      // mdmargin="0 0 100px"
      position="relative"
      index="0"
      overflow="hidden"
    >
      <Image src="assets/pattern.svg" position="absolute" width="100%" top="0" index="-2" mddisplay="none"/>
      <InnerSection>
        <Card
          flex
          justifycontent="space-between"
          alignitems="center"
          mdpadding="0 0 47px"
          mdflexdirection="column"
          mdgap="32px"
          position="relative"
          index="1"
        >
          <TitleText
            width="680px"
            mdwidth="100%"
            mdtextalign="center"
            heavy
            size="48px"
            smsize="32px"
            smlineheight="42px"
            color={white}
            lineheight="57px"
            textalign="left"
          >
            Thank you for choosing{" "}
            <span style={{ color: `${green}` }}>our digital banking</span>{" "}
            services
          </TitleText>
          {/* <Button
            padding="16px 31px"
            size="16px"
            heavy
            color={white}
            bg={green}
            radius="10px"
          >
            Open an account
          </Button> */}
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default DigitalBanking;
