import { useState } from "react";
import { missionDetails } from "../../../../data";
import { Card, green, Image, Text, TitleText, white } from "../../../ui/styles";

const RightDiv = () => {
  const [isClicked, setIsClicked] = useState(missionDetails.length + 1);

  const handleClicked = (index: any) => {
    if (isClicked === index) {
      setIsClicked(missionDetails.length + 1);
    } else {
      setIsClicked(index);
    }
  };
  return (
    <Card
      flex
      flexdirection="column"
      gap="24px"
      width="600px"
      mdwidth="100%"
      transition="0.8s ease-in-out"
    >
      {missionDetails.map((detail, index) => (
        <Card
          padding="28px 32px"
          smpadding="14px 16px"
          key={index}
          bg={green}
          radius="10px"
          flex
          flexdirection="column"
          gap="30px"
          pointer
          onClick={() => handleClicked(index)}
          data-aos="fade-up"
          transition="0.8s ease-in-out"
        >
          <Card
            flex
            justifycontent="space-between"
            alignitems="center"
            transition="0.8s ease-in-out"
          >
            <TitleText
              heavy
              size="20px"
              mdsize="16px"
              smsize=""
              lineheight="24px"
              smlineheight="20px"
              color={white}
              data-aos="fade-up"
            >
              {detail.heading}
            </TitleText>
            <Image
                src={
                  isClicked === index
                    ? "assets/close-white.png"
                    : "assets/plus-white.png"
                }
                width="13px"
                height="13px"
              />
          </Card>
          {isClicked === index &&
            (detail.details ? (
              detail.details.map((item, index) => (
                <Card flex gap="10px" alignitems="center" key={index}>
                  <Image src="assets/arrow-white.png" width="24px" />
                  <Text
                    size="14px"
                    mdsize=""
                    smsize=""
                    lineheight="20px"
                    smlineheight=""
                    color={white}
                  >
                    {item}
                  </Text>
                </Card>
              ))
            ) : (
              <Text
                size="14px"
                mdsize=""
                smsize=""
                lineheight="20px"
                smlineheight=""
                color={white}
              >
                {detail.detail}
              </Text>
            ))}
        </Card>
      ))}
    </Card>
  );
};

export default RightDiv;
