import { currentAccountOption, savingsAccountOption } from "../../../../data";
import OptionCard from "../../../ui/optionsCard";
import {
  Button,
  Card,
  gray,
  green,
  InnerSection,
  MainSection,
  TitleText,
  white,
} from "../../../ui/styles";

const SavingsAndAccountOption = () => {
  return (
    <MainSection width="100%" bg={gray} padding="55px 0 140px">
      <InnerSection>
        <Card flex flexdirection="column" alignitems="center">
          <TitleText
            size="48px"
            smsize="32px"
            smlineheight="42px"
            lineheight="57px"
            color={green}
            heavy
            center
          >
            Savings Account Options
          </TitleText>
          <Card overflow="scroll" width="100%">
            <Card
              margin="80px 0 0"
              display="grid"
              gridcolumn="repeat(4, 1fr)"
              smgridcolumn="1fr"
              gap="32px"
              justifycontent="space-between"
              smgap="30px"
              width="100%"
            >
              {savingsAccountOption.map((loan, index) => (
                <OptionCard
                  key={index}
                  title={loan.title}
                  detail1={loan.details}
                  icon={loan.icon}
                />
              ))}
            </Card>
          </Card>
          {/* <Button
            padding="16px 20px"
            color={white}
            bg={green}
            radius="10px"
            mdwidth="100%"
            mtop="50px"
          >
            Open An Account
          </Button> */}
        </Card>
        <Card
          flex
          flexdirection="column"
          alignitems="center"
          margin="100px 0 0"
        >
          <TitleText
            size="48px"
            smsize="32px"
            smlineheight="42px"
            lineheight="57px"
            color={green}
            heavy
            center
          >
            Current Account Options
          </TitleText>
          <Card overflow="scroll" width="100%">
            <Card
              margin="80px 0 0"
              display="grid"
              gridcolumn="repeat(4, 1fr)"
              smgridcolumn="1fr"
              gap="32px"
              justifycontent="space-between"
              smgap="30px"
              width="100%"
            >
              {currentAccountOption.map((loan, index) => (
                <OptionCard
                  key={index}
                  title={loan.title}
                  detail1={loan.details}
                  icon={loan.icon}
                />
              ))}
            </Card>
          </Card>
          {/* <Button
            padding="16px 20px"
            color={white}
            bg={green}
            radius="10px"
            mdwidth="100%"
            mtop="50px"
          >
            Open An Account
          </Button> */}
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default SavingsAndAccountOption;
