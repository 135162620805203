import { useState } from "react";
import Perks from "../perks";
import {
  black,
  Card,
  green,
  Image,
  Text,
  TitleText,
  white,
} from "../styles";

interface IOptionCardProps {
  icon?: string;
  title?: string;
  detail1?: string;
  detail2?: string;
  detail3?: string;
  index?: any;
  width?: string;
}

const OptionCard = (props: IOptionCardProps) => {
  const { icon, title, detail1, detail2, detail3, index, width } = props;
  const [popup, setPopup] = useState(false);
  const [clicked, setClicked] = useState(4);

  const handleClicked = () => {
    setPopup(true);
  };
  return (
    <>
      <Card
        padding="30px"
        radius="20px"
        flex
        flexdirection="column"
        justifycontent="space-between"
        gap="25px"
        bg={white}
        data-aos="fade-up"
        width={width}
        smwidth="100%"
        key={index}
        shadow="0px 4px 50px rgba(0, 0, 0, 0.05)"
      >
        <Image src={icon} width="48px" />
        <TitleText
          size="20px"
          smsize=""
          lineheight="33px"
          smlineheight=""
          color={black}
        >
          {title}
        </TitleText>
        <Text
          size="14px"
          smsize=""
          lineheight="20px"
          smlineheight=""
          color={black}
        >
          {detail1}
        </Text>
        <Card
          flex
          alignitems="center"
          gap="15px"
          pointer
          onClick={handleClicked}
        >
          <Image src="assets/arrow.png" />
          <Text
            heavy
            size="20px"
            mdsize=""
            smsize=""
            lineheight="16px"
            smlineheight=""
            color={green}
          >
            Discover more
          </Text>
        </Card>
      </Card>
      {popup && (
        <Card
          width="100vw"
          height="100vh"
          bg="rgba(0, 0, 0, 0.5)"
          left="0"
          top="0"
          position="fixed"
          index="100"
          padding="100px 0 0"
          mdpadding="50px 0 0"
        >
          <Perks title={title} detail={detail1} setPopup={setPopup} />
        </Card>
      )}
    </>
  );
};

export default OptionCard;
