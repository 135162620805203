import styled, { keyframes } from "styled-components";
import { Card, green, Image, white } from "../../../ui/styles";

const animateOnRotate = keyframes`
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const AnimationWords = styled("img")`
  transform: skew(0deg, 0deg);
  animation: ${animateOnRotate} 7s linear infinite;
`;

const AnimatingText = () => {
  return (
    <Card
      mddisplay="none"
      position="absolute"
      width="226px"
      height="226px"
      bg={green}
      radius="50%"
      top="100px"
      right="0px"
      flex
      justifycontent="center"
      alignitems="center"
      data-aos="zoom-in"
    >
      <Card
        width="226px"
        height="226px"
        flex
        justifycontent="center"
        alignitems="center"
        color={white}
      >
        {/* <CircularTextPath text="SCROLL DOWN FOR MORE - SCROLL DOWN FOR MORE" radius={100} /> */}

        <Image src="assets/hero-arrow.png" position="absolute" />
        <AnimationWords src="assets/words.png" />
      </Card>
    </Card>
  );
};

export default AnimatingText;
