import { perkDetails } from "../../../../data";
import {
  Card,
  deepGreen,
  Image,
  primaryGreen,
  Text,
  TitleText,
  white,
} from "../../../ui/styles";

const PerksAndBenefits = () => {
  return (
    <Card padding="45px 40px" smpadding="20px" bg={deepGreen} flexratio="1" radius="20px">
      <Card
        bg={primaryGreen}
        padding="45px 35px"
        smpadding="20px"
        radius="20px"
        smradius="10px"
      >
        <TitleText
          heavy
          size="24px"
          lineheight="28px"
          smsize="20px"
          smlineheight="24px"
          margin="0 0 30px"
          color={white}
        >
          Perks and Benefits
        </TitleText>
        <Card flex flexdirection="column" gap="40px" smgap="25px">
          {perkDetails.map((perk, index) => (
            <Card flex gap="20px" smgap="10px" key={index}>
              <Image
                src="assets/checkbox.png"
                width="32px"
                height="32px"
                smwidth="20px"
                smheight="20px"
              />
              <Text
                size="20px"
                lineheight="33px"
                smsize="14px"
                mdlineheight="24px"
                weight="500"
                color={white}
              >
                {perk}
              </Text>
            </Card>
          ))}
        </Card>
      </Card>
    </Card>
  );
};

export default PerksAndBenefits;
