import React from "react";
import { Card, green } from "../../../ui/styles";

const BackgroundGradient = () => {
  return (
    <>
      <Card
        mddisplay="none"
        height="666px"
        width="666px"
        position="absolute"
        filter="blur(350px)"
        bg={green}
        index="-1"
        top="355px"
        left="-254px"
      />
      <Card
        mddisplay="none"
        height="666px"
        width="666px"
        position="absolute"
        filter="blur(350px)"
        bg={green}
        index="-1"
        top="-311px"
        right="-500px"
      />
    </>
  );
};

export default BackgroundGradient;
