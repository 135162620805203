import { Card, green } from "../../../ui/styles";

const Ellipse = () => {
  return (
    <Card
      position="absolute"
      width="216px"
      height="67px"
      border={`4px solid ${green}`}
      transform="rotate(-23.08deg)"
      radius="50%"
      left="230px"
      mddisplay="none"
    />
  );
};

export default Ellipse;
