import {
  Card,
  InnerSection,
  MainSection,
  TextField,
  InputLabel,
  black,
  TextAreaField,
  Button,
  green,
  white,
} from "../../../ui/styles";

const ContactForm = () => {
  
  return (
    <MainSection width="100%" padding="110px 0">
      <InnerSection>
        <Card flex justifycontent="center">
          <Card width="1000px" mdwidth="100%">
            <form data-aos="fade-up"
              style={{ display: "flex", flexDirection: "column", gap: "50px" }}
            >
              <Card
                flex
                gap="32px"
                mdflexdirection="column"
                width="100%"
              >
                <Card flex flexdirection="column" gap="10px" flexratio="1">
                  <InputLabel htmlFor="name" color={black}>
                    Full Name
                  </InputLabel>
                  <TextField
                    id="name"
                    radius="10px"
                    border="0.2px solid #414141"
                  />
                </Card>
                <Card flex flexdirection="column" gap="10px" flexratio="1">
                  <InputLabel htmlFor="email" color={black}>
                    Your Email
                  </InputLabel>
                  <TextField
                    id="email"
                    radius="10px"
                    border="0.2px solid #414141"
                  />
                </Card>
              </Card>
              <Card
                flex
                gap="32px"
                mdflexdirection="column"
                width="100%"
              >
                <Card flex flexdirection="column" gap="10px" flexratio="1">
                  <InputLabel htmlFor="number" color={black}>
                    Phone Number
                  </InputLabel>
                  <TextField
                    id="number"
                    radius="10px"
                    border="0.2px solid #414141"
                  />
                </Card>
                <Card flex flexdirection="column" gap="10px" flexratio="1">
                  <InputLabel htmlFor="interest" color={black}>
                    What are you interested in?
                  </InputLabel>
                  <TextField
                    id="interest"
                    radius="10px"
                    border="0.2px solid #414141"
                  />
                </Card>
              </Card>
              <Card
                flex
                flexdirection="column"
                gap="10px"
              >
                <InputLabel htmlFor="message" color={black}>
                  Message
                </InputLabel>
                <TextAreaField
                  id="message"
                  radius="10px"
                  border="0.2px solid #414141"
                  height="150px"
                  size="16px"
                />
              </Card>
              <Button
                padding="16px 60px"
                bg={green}
                radius="10px"
                color={white}
                width="fit-content"
                heavy
              >
                Submit
              </Button>
            </form>
          </Card>
        </Card>
      </InnerSection>
    </MainSection>
  );
};

export default ContactForm;
