import Layout from '../components/ui/layout'
import CareersHeroSection from '../components/websitePageSections/careers/careersHeroSection'
import Roles from '../components/websitePageSections/careers/roles'

const CareersPage = () => {
  return (
    <Layout>
      <CareersHeroSection />
      <Roles />
    </Layout>
  )
}

export default CareersPage