import styled, { keyframes } from "styled-components";
import { lowerFeaturesDetails } from "../../../../data";
import { Card, green, TitleText } from "../../../ui/styles";

const animateOnSlide = keyframes`
0% { left: 0; }
100% { left: -90%; }
`;

const AnimationScreenCard = styled("div")`
  animation: ${animateOnSlide} 20s 3s linear infinite;
  width: 200%;
  position: absolute;
  display: flex;
  gap: 42px;
  align-items: center;
  //   overflow: hidden;
  bottom: 0;

  @media screen and (max-width: 1024px) {
    width: unset;
    gap: 20px;
  }
`;

const LowerFeatures = () => {
  return (
    <Card
      width="100%"
      position="relative"
      bottom="0"
      overflow="hidden"
      height="70px"
      smheight="42px"
    >
      <AnimationScreenCard>
        <Card flex gap="42px" mdgap="20px">
          {lowerFeaturesDetails.map((detail, index) => (
            <Card
              width="374px"
              mdwidth="215px"
              smwidth="155px"
              flex
              justifycontent="center"
              alignitems="center"
              padding="20px 0"
              mdpadding="20px 0"
              smpadding="10px 0"
              border={`1px solid ${green}`}
              radius="10px"
              key={index}
            >
              <TitleText
                heavy
                size="20px"
                lineheight="24px"
                mdsize="18px"
                smsize="14px"
                mdlineheight="20px"
              >
                {detail}
              </TitleText>
            </Card>
          ))}
        </Card>
        <Card flex gap="42px" mdgap="20px">
          {lowerFeaturesDetails.map((detail, index) => (
            <Card
              width="374px"
              mdwidth="215px"
              smwidth="155px"
              flex
              justifycontent="center"
              alignitems="center"
              padding="20px 0"
              smpadding="10px 0"
              border={`1px solid ${green}`}
              radius="10px"
              key={index}
            >
              <TitleText
                heavy
                size="20px"
                lineheight="24px"
                mdsize="18px"
                smsize="14px"
                mdlineheight="20px"
              >
                {detail}
              </TitleText>
            </Card>
          ))}
        </Card>
      </AnimationScreenCard>
    </Card>
  );
};

export default LowerFeatures;
